import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import axios from 'axios';
import { Button, Card, Container, CssBaseline, Grid, CardMedia, CardContent, Divider, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { doc, getDoc } from 'firebase/firestore';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RedeemPoints() {
  const [points, setPoints] = useState(0);
  const [requiredPoints, setRequiredPoints] = useState(100); 
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserPoints = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      setPoints(userDoc.data().points || 0);
    };

    if (user) {
      fetchUserPoints();
    }
  }, [user]);

  const handleRedeem = async () => {
    if (points >= requiredPoints) {
      const coupon = 'DISCOUNT_CODE'; 
      await db.collection('users').doc(user.uid).update({
        coupons: db.FieldValue.arrayUnion(coupon),
        points: points - requiredPoints
      });
      alert('Coupon unlocked!');
    } else {
      alert('Not enough points!');
    }
  };

  return (
    <div>
      <h2>Your Points: {points}</h2>
      <button onClick={handleRedeem}>Redeem for Discount</button>
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState('');
  const [unlockedCards, setUnlockedCards] = useState([]);
  const [points, setPoints] = useState(0);
  const [previousPoints, setPreviousPoints] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUnlockedCards(userData.cards || []);
          setPoints(userData.points || 0);
          setPreviousPoints(userData.points || 0);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('User Info:', result.user);
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setNotification('Inicia sesión primero.');
      setOpen(true);
      return;
    }
    try {
      const response = await axios.post('http://localhost:5000/unlock-card', { code: code });
      setUnlockedCards((prevCards) => [...prevCards, response.data.card]);
      const pointsEarned = parseInt(response.data.card.points, 10) || 0; // Asegúrate de que los puntos sean un número

      // Actualiza los puntos en Firestore
      await db.collection('users').doc(user.uid).update({
        points: db.FieldValue.increment(pointsEarned)
      });

      // Actualiza el estado local de puntos
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const currentPoints = userDoc.data().points || 0;
      setPoints(currentPoints + pointsEarned); // Actualiza los puntos del usuario en la interfaz

      setNotification(response.data.message);
      setOpen(true);
    } catch (error) {
      setNotification('Error, intenta nuevamente.');
      console.error('Error:', error);
    }
  };

  const handleRedeemCode = async (code) => {
    try {
      const response = await fetch('/api/redeem-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });
      const data = await response.json();
      if (data.success) {
        setPoints(data.newPoints);
        alert(`¡Has ganado ${data.newPoints - previousPoints} puntos!`);
        setPreviousPoints(data.newPoints); // Guardar los puntos actuales para el cálculo
        alert('Código canjeado con éxito!');
      } else {
        alert('Error al canjear el código: ' + data.message);
      }
    } catch (error) {
      console.error('Error al canjear el código:', error);
    }
  };

  return (
    <div style={{ backgroundColor: '#000', color: '#fff', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
          {notification}
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="md" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CssBaseline />
        <img src="/logo.png" alt="Styline Logo" style={{ marginBottom: '2rem', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '300px' }} />
        {user ? (
          <>
            <Button variant="outlined" onClick={handleLogout} style={{ marginBottom: '2rem', borderColor: '#fff', color: '#fff' }}>Cerrar sesión</Button>
            <form onSubmit={handleCodeSubmit} style={{ width: '100%', maxWidth: '400px', marginBottom: '2rem' }}>
              <TextField type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Ingresa el código" fullWidth variant="outlined" style={{ marginBottom: '1rem', backgroundColor: '#fff', borderRadius: '4px' }} />
              <Button type="submit" variant="contained" fullWidth style={{ backgroundColor: '#fff', color: '#000' }}>Desbloquear</Button>
            </form>
            <Grid container spacing={4} justifyContent="center">
              {unlockedCards.map((card, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card style={{
                    backgroundColor: '#1c1c1c',
                    color: '#fff',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                  }} onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05) rotateY(10deg)'} onMouseLeave={e => e.currentTarget.style.transform = 'scale(1) rotateY(0deg)'}>
                    <CardMedia
                      component="img"
                      alt={`Image for ${card.title}`}
                      image={card.image}
                      title={card.title}
                    />
                    <CardContent style={{ padding: '1rem' }}>
                      <Typography variant="h5" style={{ fontWeight: 'bold' }}>{card.title}</Typography>
                      <Typography variant="subtitle1">{card.subtitle}</Typography>
                      <Typography variant="body2" style={{ marginTop: '0.5rem' }}>{card.description}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <RedeemPoints />
          </>
        ) : (
          <Button variant="contained" onClick={handleLogin} fullWidth style={{ backgroundColor: '#fff', color: '#000', marginTop: '2rem' }}>Iniciar sesión</Button>
        )}
      </Container>
    </div>
  );
}

export default App;
